#project-titles {
  figure {
    @include breakpoint-down {
      margin: get-vw(10px) 0 get-vw(180px);
    }
    
    picture {
      @include breakpoint-down {
        display: block;
        width: 100%;
        position: relative;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;

        @include breakpoint-down {
          width: 100%;
          height: 100%;
        }

        @include breakpoint {
          opacity: 0;
          position: fixed;
          bottom: 0;
          right: 0;
          z-index: -1;
          max-width: calc(100% - #{get-vw(60px)});
          max-height: 100%;
          margin: auto;
          transition: opacity .3s ease;
          will-change: opacity;
        }
      }
    }
  }

  li {
    float: left;
    clear: both;

    .no-touchevents &:hover {
      img {
        opacity: 1;
      }
    }
  }
}