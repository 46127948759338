.grid-guide {
  @include breakpoint {
    position: fixed;
    width: calc(100% - #{get-vw(30px)});
    height: calc(100% - 92px);
    padding: 40px get-vw(15px) 50px;
    top: 0;
    left: 0;
    display: flex;
    align-items: stretch;
    z-index: -1;

    &__col {
      width: calc(100% / 12);
      height: 100%;
      margin: 0 get-vw(15px);
      border: 1px dashed rgba(255, 0, 0, 0.6);
    }
  }
}