// Base
// Colours
$sn_black: #121212;
$sn_grey: #F0f0f0;

// Typography
$font_bold: 'Gerstner-ProgrammBold', helvetica, sans-serif;
$font_italic: 'Gerstner-ProgrammItalic', helvetica, sans-serif;

// Breakpoints 
$breakpoint_XL: 1920;
$breakpoint_L: 1200;
$breakpoint_M: 900;
$breakpoint_S: 768;
$breakpoint_XS: 560;