.profile-image {
  margin-bottom: 10px;
  
  picture {
    display: block;
    width: 100%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.subscribe {
  background-color: $sn_black;
  color: white;
  text-align: center;
  padding: 12px 0 40px;
  margin: 0 -#{get-vw(15px)};
  cursor: pointer;
}

.footnote {
  margin: 60px 0 30px;
}

.about .modal {
  display: flex;
}

.response {
  &--success {
    color: rgb(0,255,0);
  }
  &--error {
    color: rgb(255,0,0);
  }
} 