@font-face {
  font-family: 'Gerstner-ProgrammBold';
  src: url('../../fonts/Gerstner-ProgrammBold.woff2') format('woff2'),
       url('../../fonts/Gerstner-ProgrammBold.woff') format('woff');
}

@font-face {
  font-family: 'Gerstner-ProgrammBoldItalic';
  src: url('../../fonts/Gerstner-ProgrammBoldItalic.woff2') format('woff2'),
       url('../../fonts/Gerstner-ProgrammBoldItalic.woff') format('woff');
}