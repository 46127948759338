.slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 60;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95);

    .cssfilters & {
      background: none;
    }
  }

  &__counter {
    position: absolute;
    bottom: get-vw(30px);
    right: get-vw(30px);
  }

  &__exit {
    position: absolute;
    top: get-vw(60px);
    right: get-vw(60px);
    width: 8vw;
    height: 8vw;
    z-index: 40;

    @include breakpoint {
      top: get-vw(30px);
      right: get-vw(30px);
      width: get-vw(48px);
      height: get-vw(48px);
    }

    &:hover {
      cursor: pointer;
      opacity: .5;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__items {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;

    &.cursor-is-left {
      .flickity-viewport {
        cursor: url("/assets/img/ui/arrow-left.svg") 45 0, auto;
      }
    }

    &.cursor-is-right {
      .flickity-viewport {
        cursor: url("/assets/img/ui/arrow-right.svg") 45 0, auto;
      }
    }
  }

  &__item {
    width: 100%;
    height: 100%;
    position: relative;

    img, .iframe-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      max-width: calc(100% - #{get-vw(120px)});
      max-height: 100%;
      opacity: 1;

      @include breakpoint {
        max-width: calc(100% - #{get-vw(400px)});
      }
    }

    picture {
      opacity: 0;
      transition: opacity .3s ease;

      &.image-loaded {
        opacity: 1;
      }
    }
  }

  .iframe-container {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .iframe-wrapper {
    width: 100%;
    padding-bottom: 56%;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}