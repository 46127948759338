// Breakpoints
@mixin large-screens {
  @media (min-width: #{$breakpoint_XL}px) {
    @content;
  }
}

@mixin breakpoint {
  @media (min-width: #{$breakpoint_M}px) {
    @content;
  }
}

@mixin breakpoint-down {
  @media (max-width: #{$breakpoint_M - 1}px) {
    @content;
  }
}

@mixin portrait {
  @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 2) 
  and (orientation: portrait) {
    @content;
  }
}


// Units conversions
@mixin vw($size) {
  font-size: get-vw($size);

  // Prevent font-size from getting too big
  @include large-screens {
    font-size: $size;
  }
}

