nav {
  top: 0;
  position: fixed;
  z-index: 60;
  color: $sn_grey;
  width: 100%;
  padding: get-vw(15px) 0 0;

  @include breakpoint {
    top: 15px;
    left: get-vw(30px);
    width: auto;
    z-index: 30;
    padding: 0;
  }

  @include breakpoint-down {
    &:not(.is-open) li:nth-child(n+2) {
      display: none;
    }
  }

  .about:not(.modal-is-open) &, .projects &, .project:not(.gallery-is-open) &, .news &, &.is-open {
    @include breakpoint-down {
      background-color: white;
      box-shadow: rgba(121, 121, 121, 0.25) 0px 10px 15px;
    }
  }

  .home &, .modal-is-open & {
    color: black;
  }

  .gallery-is-open & {
    filter: blur(20px);
  }

  .invert-nav &:hover {
    color: black;
  }

  // parent hover state
  .no-touchevents body:not(.invert-nav) &:hover {
    color: $sn_grey;
  }

  // #nav
  ul {
    padding: 0 get-vw(60px) get-vw(30px);

    @include breakpoint {
      padding: 0;
    }
  }

  // selected states
  .no-touchevents & li:hover > a, li:not(:first-child).is-active > a, .no-touchevents & .sub-nav li:hover > a, .sub-nav li.is-active > a {
    color: black;
    cursor: pointer;
  }

  .no-touchevents .invert-nav & li:hover > a {
    color: $sn_grey;
  }

  // Mobile nav icon
  #nav-icon {
    height: auto;
    flex: 1;
    text-align: right;

    @include breakpoint {
      display: none;
    }

    img {
      display: inline-block;
      vertical-align: bottom;
      width: 8vw;
      padding-bottom: get-vw(12px);
    }

    .no-touchevents &:hover {
      cursor: pointer;
    }
  }
}

.sub-nav {
  display: none;
  padding-left: 0.76em;

  .projects & {
    display: block;
  }
}

.flex {
  display: flex;

  &--stretch {
    align-items: stretch;
  }

  @include breakpoint {
    display: list-item;
  }
}