.selected-entries {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;
  scroll-snap-points-y: repeat(100vh);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
  backface-visibility: hidden;

  &__item {
    scroll-snap-align: start;
    position: relative;
    width: 100%;
    height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;

    &--inverted {
      background-color: $sn_black;
      color: white;
    }

    & > div {
      flex: 1;
    }

    .txt-D {
      h2 {
        display: inline-block;
        @extend .cols--10;

        @include breakpoint-down {
          width: calc(100% - #{get-vw(60px)});
          hyphens: auto;
          hyphenate-limit-chars: 10 4 4;
          -webkit-hyphenate-limit-before: 4; 
          -webkit-hyphenate-limit-after: 4;
        }
      }
    }

    .img {
      text-align: center;
      width: 100%;
      display: block;
      height: 100%;

      figure {
        display: inline-block;
        position: relative;
        height: calc(100% - 90px);
        width: 100%;
        padding: 40px 0 50px;

        @include breakpoint-down {
          width: calc(100% - #{get-vw(120px)});
          padding: 40px get-vw(60px) 50px;
          height: calc(100% - 90px);
        }

        picture {
          height: calc(100% - 27px);
          display: flex;
          align-items: center;

          @include breakpoint-down {
            height: calc(100% - #{get-vw(53px)});
          }

          @include breakpoint {
            margin: 0 80px;
            max-width: calc(100% - 160px);
          }
        }
      }

      figcaption {
        margin-top: 10px;
        width: 100%;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        position: relative;
        z-index: 50;
        margin: 0 auto;
      }
    }
  }
}