.project-title {
  margin-bottom: get-vw(60px);

  @include breakpoint {
    margin-bottom: get-vw(30px);
  }
}

#text {
  &.is-open {
    @include breakpoint-down {
      p {
        &:after {
          content: "";
        }

        span:nth-child(n+2) {
          display: block;
          margin-bottom: .5em;
        }
      }
    }
  }

  p {
    margin: 0;

    br {
      display: none;
    }

    @include breakpoint-down {
      span {
        &:first-child {
          display: block;
          margin-bottom: .5em;
        }
        
        &:nth-child(n+2) {
          display: none;
        }
      }

      &:after {
        content: attr(data-read-more) "Read more";
        display: inline-block;
        border-bottom: .1em solid black;
      }
    }
  }
}

.project ul:not(.nav) {
  @include breakpoint-down {
    padding-right: 40px;
  }
}

.hero {
  margin-bottom: get-vw(60px);

  @include breakpoint {
    margin-bottom: get-vw(10px);
  }
  
  picture {
    display: block;
    width: 100%;
    position: relative;
    cursor: pointer;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.gallery {
  margin-top: get-vw(30px);

  &__item {
    @include breakpoint-down {
      margin: 0 get-vw(60px) get-vw(60px) 0;
      width: 100%;
      height: auto;
    }

    @include breakpoint {
      float: left;
      margin: 0 get-vw(30px) get-vw(30px) 0;
      width: auto;
      height: get-vw(250px);
    }

    &:hover {
      cursor: pointer;
    }

    img {
      @include breakpoint-down {
        width: 100%;
      }

      @include breakpoint {
        height: 100%;
      }
    }
  }
}

.related-news {
  background-color: $sn_black;
  color: white;

  @include breakpoint {
    margin: 0 -#{get-vw(15px)};
  }

  &__inner {
    @include breakpoint {
      margin: 0 get-vw(15px);
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  &__item {
    padding: get-vw(40px) 0 get-vw(180px);

    @include breakpoint {
      padding: get-vw(40px) 0;
    }

    h3 {
      margin-bottom: 10px;
    }

    a {
      &:hover {
        cursor: pointer;
      }
    }

    &__date {
      span {
        display: inline-block;
        vertical-align: middle;
      }
  
      .small-arrow {
        margin: 0 .5em 0 .2em;
        width: 1em;
        height: 1em;
        background: url("/assets/img/ui/arrow-right-white.svg") no-repeat center center;
        background-size: contain;
      }
  
      a {
        .no-touchevents &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// Blur effects
.gallery-is-open {
  .hero picture, .hero iframe, .hero .iframe-poster, .txt, .txt-S, section .txt-L, .related-news, .gallery__item img {
    filter: blur(20px);
  }
}