select {
  -webkit-appearance: none;
  border-radius: 0;
  margin: 0;
}

input:not([type="submit"]),
textarea,
select {
  font-size: 100%;
  outline: 0;
  border: none;
  font-weight: normal;
  padding: 0;
  line-height: 100%;
  -webkit-appearance: none;
  border-radius: 0;
  display: block;
  width: 100%;
  background-color: $sn_black;
  color: white;
  font-family: $font_bold;
  text-transform: uppercase;
  text-align: center;
}