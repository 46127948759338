.post {
  position: relative;
  margin-bottom: 1.5em;

  @include breakpoint-down {
    margin-bottom: get-vw(180px);
  }

  & > div {
    float: left;

    @include breakpoint-down {
      float: none;
    }
  }

  &__title {
    &:hover {
      cursor: pointer;
    }

    h3 {
      hyphens: auto;
    }
  }

  &__img {
    margin-top: get-vw(15px);
    margin-bottom: get-vw(15px);
    will-change: margin-left, width;
    backface-visibility: hidden;
    transition: width .4s ease, margin-left .4s ease;

    @include breakpoint-down {
      figure {
        margin: 0;
      }
    }

    picture {
      display: block;
      width: 100%;
      position: relative;
      background-color: $sn_grey;
    }

    img {
      will-change: width;
      position: absolute;
      z-index: 50;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .is-open & {
      margin-left: 0px;
      @extend .cols--7;
    }
  }

  &__date {
    span {
      display: inline-block;
      vertical-align: middle;
    }

    .small-arrow {
      margin: 0 .5em;
      width: 1em;
      height: 1em;
      background: url("/assets/img/ui/arrow-right.svg") no-repeat center center;
      background-size: contain;
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__text {
    visibility: hidden;
    height: 0;
    opacity: 0;
    will-change: opacity;
    transition: opacity .4s ease 0;

    @include breakpoint-down {
      display: none;

      .is-open & {
        display: block;
      }
    }

    .is-open & {
      height: auto;
      visibility: visible;
      opacity: 1;
      transition: opacity .4s ease .4s;
    }
  }
}