.cols {
  @include breakpoint {
    @for $i from 1 through 12 {
      &--#{$i} {
        width: cols($i);
      }
    }
  }
}

.offset {
  @include breakpoint {
    @for $i from 1 through 12 {
      &--#{$i} {
        margin-left: cols($i);
      }
    }
  }
}

.grid-item {
  margin: 0 get-vw(60px);

  @include breakpoint {
    margin: 0 get-vw(15px);
  }
}