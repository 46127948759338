.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $sn_black;
  color: white;
  z-index: 20;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s ease-in;
  display: none;

  .modal-is-open & {
    opacity: 1;
    pointer-events: all;
  }

  &__exit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__content {
    text-align: center;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  h3 {
    margin-bottom: 1em;
  }

  input:not([type="submit"]) {
    margin-bottom: 1em;
  }

  input[type="submit"] {
    display: inline-block;
    font-size: 100%;
    outline: 0;
    border: none;
    font-weight: normal;
    padding: 0;
    padding-bottom: get-vw(20px);
    line-height: 100%;
    -webkit-appearance: none;
    border-radius: 0;
    background-color: $sn_black;
    color: white;
    font-family: $font_bold;
    text-transform: uppercase;
    text-align: center;
    border-bottom: get-vw(7px) solid white;

    &:hover {
      cursor: pointer;
    }
  }
}