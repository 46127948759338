.spacing {
  margin-bottom: get-vw(180px);

  @include breakpoint {
    margin-bottom: get-vw(90px);
  }
}

.spacing-S {
  margin-bottom: get-vw(60px);

  @include breakpoint {
    margin-bottom: 20px;
  }
}