figure {
  img {
    opacity: 0;
    transition: opacity .6s ease;
  }

  &.image-loaded {
    img {
      opacity: 1;
    }
  }
}