.txt {
  font-size: 4.5333333333vw;
  line-height: 1.15em;

  @include breakpoint {
    font-size: 35px;
    @include vw(35px);
  }
}

.txt-XS {
  font-size: 4.5333333333vw;
  line-height: 1.15em;

  @include breakpoint {
    font-size: 14px;
    line-height: 1.05em;
  }
}

.txt-S {
  font-size: 4.5333333333vw;
  line-height: 1.15em;

  @include breakpoint {
    line-height: 1.05em;
    font-size: 24px;
    @include vw(24px);
  }
}

.txt-L {
  font-size: 10.6666666667vw;
  line-height: 1.08em;
  text-transform: uppercase;

  @include breakpoint {
    font-size: 60px;
    @include vw(60px);
  }
}

.txt-D {
  font-size: 10.6666666667vw;
  line-height: 1.08em;
  text-transform: uppercase;

  @include breakpoint {
    font-size: 130px;
    @include vw(130px);
    line-height: .96em;
  }
}
